import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout/Layout";
import Main from "@pages/Main";
import PictureSend from "@pages/PictureSend";
import PictureInput from "@pages/PictureInput";
import Success from "@pages/Success";
import "tailwindcss/tailwind.css";

const CustomRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <span>404</span>,
    children: [
      {
        path: "",
        element: <Main />,
      },
      {
        path: "picture_send",
        element: <PictureSend />,
      },
      {
        path: "picture_input",
        element: <PictureInput />,
      },
      {
        path: "success",
        element: <Success />,
      },
    ],
  },
]);

export default CustomRouter;
