import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { reqURLAPI, useBackNavigation } from "@features/index";
import { ampli } from "../ampli";

const PictureInput: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  var initialImageCount = location.state?.files.length || 0;

  const { files } = location.state || {};

  const [renderImages, setRenderImages] = useState<(string | null)[]>(
    Array(8).fill(null)
  );
  const imgCount = renderImages.filter((img) => img !== null).length;
  const [sendFile, setSendFile] = useState<(File | null)[]>((Array(8).fill(null)));

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [nextBtnDisable, setNextBtnDisable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useBackNavigation("메인 페이지로 돌아가시겠습니까?", "/");

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    refreshImages(Array.from(files));
  };

  const refreshImages = (files: File[]) => {
    if (files.length >= 8) {
      files = files.slice(0, 8);
    }

    if (files.length != 0) {
      const remainingSlots = renderImages.filter((img) => img === null).length;
      const filesToProcess = Array.from(files).slice(0, remainingSlots);

      filesToProcess.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const newImageData = e.target?.result as string;

          if (renderImages.includes(newImageData)) {
            alert("이미 등록된 이미지가 포함되어 있습니다.");
            return;
          }

          setRenderImages((prevImages) => {
            const newImages = [...prevImages];
            const emptyIndex = newImages.findIndex((img) => img === null);

            if (emptyIndex !== -1) {
              newImages[emptyIndex] = newImageData;

              setSendFile((prev) => {
                const newSendFile = [...prev];
                newSendFile[emptyIndex] = file;
                return newSendFile;
              });
            }

            return newImages;
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  // const handleDeleteImg = (index: number) => {
  //   const newImg = renderImages.filter((img, i) => i !== index && img !== null);
  //   const filledSlots = newImg.length;
  //   const updatedImages = [...newImg, ...Array(8 - filledSlots).fill(null)];
  //   setRenderImages(updatedImages);

  //   setSendFile((prev) => {
  //     return prev.filter((_, i) => i !== index);
  //   });
  // };

  const handleDeleteImg = (index: number) => {
    setRenderImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = null;
      return newImages;
    });

    setSendFile((prevSendFile) => {
      const newSendFile = [...prevSendFile];
      newSendFile[index] = null;
      return newSendFile;
    });
  };

  const handleNextBtn = async () => {
    setIsLoading(true);
    setNextBtnDisable(false);
    try {
      const filesToSend = sendFile.filter((file): file is File => file !== null);
      const res = await reqURLAPI(filesToSend);
      console.log(res);

      if (res.status === 200 || res.status === 201) {
        ampli.imageUpload();
        navigate("/picture_send", { state: { resultUrl: res.data.result } });
      }
    } catch (error) {
      console.log(error);
      alert("이미지 업로드에 실패했습니다. 다시 시도해주세요");
    } finally {
      setIsLoading(false);
      setNextBtnDisable(true);
    }
  };

  useEffect(() => {
    setNextBtnDisable(imgCount === 0);
  }, [imgCount]);

  useEffect(() => {
    if (initialImageCount > 0) {
      refreshImages(files);
    }
    initialImageCount = 0;
  }, [initialImageCount, files]);

  const renderImageBoxes = () => {
    return (
      <div className="grid grid-cols-3 gap-2 mt-4 font-pretendard">
        <div
          className="flex flex-col items-center justify-center w-24 h-24 bg-p3_black cursor-pointer rounded-[10px]"
          onClick={() => fileInputRef.current?.click()}
        >
          {/* 카메라 이미지 svg */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
          >
            <path
              d="M18.8235 5.79309V3.51724C18.8235 3.11484 18.6624 2.72893 18.3756 2.44439C18.0887 2.15985 17.6997 2 17.2941 2H12.7059C12.3002 2 11.9112 2.15985 11.6244 2.44439C11.3376 2.72893 11.1765 3.11484 11.1765 3.51724V5.79309"
              fill="white"
            />
            <path
              d="M18.8235 5.79309V3.51724C18.8235 3.11484 18.6624 2.72893 18.3756 2.44439C18.0887 2.15985 17.6997 2 17.2941 2H12.7059C12.3002 2 11.9112 2.15985 11.6244 2.44439C11.3376 2.72893 11.1765 3.11484 11.1765 3.51724V5.79309"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26 6.18909H4C2.89543 6.18909 2 7.07511 2 8.16808V22.0211C2 23.114 2.89543 24.0001 4 24.0001H26C27.1046 24.0001 28 23.114 28 22.0211V8.16808C28 7.07511 27.1046 6.18909 26 6.18909Z"
              fill="white"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.9999 19.0523C17.209 19.0523 18.9999 17.2803 18.9999 15.0943C18.9999 12.9084 17.209 11.1364 14.9999 11.1364C12.7907 11.1364 10.9999 12.9084 10.9999 15.0943C10.9999 17.2803 12.7907 19.0523 14.9999 19.0523Z"
              fill="#27282F"
              stroke="#27282F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {/* 카메라 아래 숫자 */}
          <p className="text-p3_white text-[16px] font-medium">{imgCount}/8</p>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageUpload}
            accept="image/*"
            multiple
            className="hidden"
          />
        </div>
        {renderImages.map((img, index) => (
          <div
            key={index}
            className="relative w-24 h-24 bg-p3_gray3 overflow-hidden rounded-[10px]"
          >
            {img && (
              <>
                <img
                  src={img}
                  alt={`Uploaded ${index}`}
                  className="w-full h-full object-cover"
                />
                <button
                  className="absolute top-0 right-0 bg-p3_white rounded-full"
                  onClick={() => handleDeleteImg(index)}
                >
                  {/* 삭제 이미지 svg */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                      stroke="#CBCFD9"
                    />
                    <path
                      d="M13 7L7 13"
                      stroke="#7C869D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 7L13 13"
                      stroke="#7C869D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center bg-p3_white h-[100svh]">
      <img src="/top.svg?v=1" alt="topImg" className="mt-[25px]" />
      <div className="flex mt-[35px] text-[20px] text-p3_black">
        <span className="font-bold">여자 단발 사진</span>
        <p className="font-medium">을 보내주세요.</p>
      </div>
      <p className="text-p3_gray1 text-[14px] font-medium mb-[34px]">
        보내주신 머리는 손이고가 대신 자랑해 드립니다.
      </p>
      <div>{renderImageBoxes()}</div>
      <button
        className={`flex justify-center items-center w-[320px] py-[12px] bg-p3_black rounded-[10px] mt-auto mb-[35px] ${
          nextBtnDisable ? "bg-p3_gray2" : "bg-p3_black"
        } ${isLoading ? "bg-p3_gray2" : "bg-p3_black"}`}
        onClick={handleNextBtn}
        disabled={nextBtnDisable}
      >
        <p className="w-full text-p3_white text-[18px] font-semibold">
          {isLoading ? "처리 중.." : "다음"}
        </p>
      </button>
    </div>
  );
};

export default PictureInput;
