import axios from "axios";

const api = axios.create({
  baseURL: "https://test.jam.ong",
  withCredentials: false,
});

export const pictureSendAPI = async (
  userId: string,
  message: string,
  imageUrls: string
) => {
  try {
    const res = await api.post("/donation/image-urls", {
      userId,
      message,
      imageUrls,
    });
    console.log(res.data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
