import axios from "axios";

const api = axios.create({
  baseURL: "https://test.jam.ong",
  withCredentials: false,
});

export const getUserIdAPI = async (instagramId: string) => {
  try {
    const res = await api.post(
      "/user/register/get-or-create/insta-id", {
        instagramId: instagramId
      }
    );
    console.log(res);
    return res;

  } catch (error) {
    console.log(error);
  }
};
