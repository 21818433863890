import React from "react";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <div className="flex flex-col justify-center items-center font-pretendard">
      <div className="w-full max-w-[360px] mx-auto">
        <Outlet />
      </div>
    </div>
  );
};
