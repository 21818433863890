import "tailwindcss/tailwind.css";
import { RouterProvider } from "react-router-dom";
import CustomRouter from "./AppRouter";
import { ApiKey, DefaultConfiguration, ampli } from "../ampli";

const AMPLI_CONFIG = DefaultConfiguration;
const AMPLI_API_KEY = ApiKey.default;

ampli.load({
  client: {
    apiKey: AMPLI_API_KEY,
    configuration: AMPLI_CONFIG,
  },
});

function App() {
  return (
    <>
      <RouterProvider router={CustomRouter} />
    </>
  );
}

export default App;
