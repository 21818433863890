import React, { ChangeEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ampli } from "../ampli";

const Main = () => {
  const navigate = useNavigate();

  const divRef = useRef<HTMLDivElement>(null);

  const navigateToNext = (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    navigate("/picture_input", { state: { files } });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageStore = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    navigateToNext(Array.from(files));
  };

  const handleBtnClick = () => {
    ampli.startChoosingImage();
    fileInputRef.current?.click()
  }

  return (
    <div
      className="flex flex-col items-center bg-p3_white h-[100svh]"
      ref={divRef}
    >
      <div className="mt-[23px]">
        <img src="/top.svg?v=1" alt="topImg" />
      </div>
      <div className="flex flex-col items-center justify-center w-[320px] h-[80px] bg-p3_gray3 mt-[22px] rounded-[11px]">
        <p className="text-[#635750] text-sm font-medium mt-[14px]">
          오늘의 보-물
        </p>
        <p className="text-p3_black text-xl font-bold mb-[15px]">
          여자 단발 사진
        </p>
      </div>
      <img src="/mainImg.png?v=2" alt="mainImg" className="w-[360px] mt-[8px]" />
      <div
        className="flex justify-center items-center w-[320px] py-[12px] bg-p3_black rounded-[10px] mt-auto mb-[35px] cursor-pointer"
        onClick={handleBtnClick}
      >
        <p className="text-p3_white text-[18px] font-semibold">머리 공유하기</p>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleImageStore}
          multiple
          className="hidden"
        />
      </div>
    </div>
  );
};

export default Main;
