import axios, { AxiosResponse } from "axios";

const api = axios.create({
  baseURL: "https://test.jam.ong",
  withCredentials: false,
});

export const reqURLAPI = async (files: File[]): Promise<AxiosResponse> => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  try {
    const res = await api.post("/donation/pre-upload", formData);
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
